.map-warp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
a{
  &.mapboxgl-ctrl-logo{
    display: none !important;
  }
}
#root{
  width: 100%;
  height: 100%;
}
.root-sub{
  display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.App {
  position: relative;
  height: 100%;
  flex: 1;
}

.app-title {
  text-align: center;
  color: #3f51b5;
  margin: 0;
  padding: 20px 0;
}
.mapboxgl-ctrl-top-right{
  margin-top: 60px;
}
.mapboxgl-popup-content{
  width: 200px;
}
.right-top-container {
  position: absolute;
  top: 10px;
  right: 50px;
  left: auto;
  overflow: auto;
}

.left-top-conationer {
  position: absolute;
  top: 20px;
  left: 20px;
  right: auto;
  overflow: auto;
  min-width: 300px;
}

.left-control-panel {
  max-width: 320px;
}

.left-bottom-container {
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 10px;
  right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.right-bottom-conatiner {
  position: absolute;
  top: auto;
  bottom: 10px;
  left: auto;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.mapStyle {
  padding: 2px;
  border-radius: 5px;

  &.active {
    background-color: #FFF;
  }
}

.mapStyle-cont {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-preview {
  height: 40px;
  width: 60px;
  overflow: hidden;
  border-radius: 5px 5px 0 0;

  img {
    width: 150%;
    height: auto;
  }
}

.map-name {
  height: 20px;
  width: 60px;
  background-color: #FFF;
  line-height: 20px;
  font-size: 12px;
  color: #333;
  border-radius: 0 0 5px 5px;
  text-align: center;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}

.flex-column-scroll {
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
}

.map-style {
  // width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.mapboxgl-control-container {
  .mapboxgl-ctrl-top-left {
    position: absolute;
    top: 20px;
    left: 350px;

    .mapboxgl-ctrl {
      margin: 0;
    }
  }
}

.my-map {
  z-index: 0;
  opacity: 0;

  >div {
    opacity: 0;
    transition: all .35s ease-in;
  }

  &.show {
    z-index: 10;
    opacity: 1;

    >div {
      z-index: 10;
      opacity: 1;
    }
  }
}

.resium-map {
  z-index: 0;
  opacity: 0;

  transition: all .4s ease-out;

  &.show {
    z-index: 10;
    opacity: 1;
  }
}

.cesium-widget-credits {
  display: none !important;
}

.popup-container-img {
  width: 50px !important;
  height: 100px;
}

.popup-container__title {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.popup_item {
  padding: 2px 0;

  &__title {
    font-weight: 600;
    padding-right: 10px;
  }
}