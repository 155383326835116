.center-control-panel {
    width: 320px;

    .ant-collapse-header {
        background-color: #3f51b5;
        color: #FFF !important;
        cursor: move !important;

        i {
            cursor: pointer !important;
        }
    }

    .pd24 {
        padding: 12px 20px;
    }

    .radio-group-item {
        .ant-radio-wrapper {
            width: 50%;
            padding-bottom: 5px;
            margin: 0;
        }

    }
}

.legend-card {
    width: 100px;
    padding: 10px 15px 15px;
    margin-bottom: 20px;

    .legend-card__title {
        padding-bottom: 5px;
        text-align: left;
        font-weight: bold;
    }
}

.legend-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
}

.legend-cont {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.color-line {
    width: 100%;
    height: 10px;
    border-radius: 5px;
}

.color-cont__item {
    display: block;
    white-space: nowrap;
    margin-bottom: 5px;
}

.legend__title {
    padding: 5px 0;
    display: block;
}

.tip-tool {
    position: relative;

    .ant-card-body {
        background-color: #333;
    }

    .tip-close {
        position: absolute;
        top: -5px;
        right: -5px;
    }
}